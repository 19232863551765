import React from "react";
import Helmet from "react-helmet";

function Privacy() {
  return (
    <div className="container-fluid mb-5 maxWidth margin-header">
      <Helmet>
        <title>Privacy Policy - PM Garcia Gonzalez</title>
        <meta name="title" content="Privacy Policy - PM GARCIA GONZALEZ" />
        <meta
          name="description"
          content="An overview of the privacy policies of PM Garcia Gonzalez with information on their website. By visiting this website, you accept following Terms of Use"
        />
        <link rel="canonical" href="https://pmgarciagonzalez.com/privacy" />
      </Helmet>
      <h1 className="text-center my-5" data-aos="fade"><span className="mustard"> - </span>PRIVACY<span className="mustard"> - </span></h1>
      <p data-aos="fade">
        PM Garcia Gonzalez recognizes the need to protect all information deemed
        personal and private, and requires as a matter of policy that any and
        all information submitted online, such as name, company, email, phone,
        etc. be used solely for the express purpose of responding to your
        inquiry. All personal information is held in confidence, and is only
        shared with your express consent. It is not our policy to send
        unsolicited emails, and all users can unsubscribe from our email mailing
        list at any time.
      </p>

      <p data-aos="fade">
        Additionally, it is PM Garcia Gonzalez policy to prohibit the sale and/or
        other distribution of personal information to third party companies, and
        PM Garcia Gonzalez will enforce all privacy agreements to protect our
        customer information.
      </p>

      <p data-aos="fade">
        PM Garcia Gonzalez is committed to protecting your privacy, and all persons
        with any privacy concerns or questions are asked to contact
        PM Garcia Gonzalez for further clarification.
      </p>
    </div>
  );
}

export default Privacy;
