import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Swal from "sweetalert2";
import "./Contact.scss";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";

export default function Contact() {
  const [Check, setCheck] = useState(false);
  const [value, setValue] = useState();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ic4mfqh",
        "template_0ckvnwn",
        e.target,
        "TnqHyryl-Fi2pafAL"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }
  const check = () => {
    let agree = document.querySelector("#contactFormAgree");

    let status = agree.checked ? true : false;
    setCheck(status);
  };

  const showAlert = () => {
    if (Check) {
      Swal.fire({
        title: "Confirmed",
        text: "We have received your message, we will review the information and be in touch soon.",
        icon: "success",
        button: "OK",
        timer: "3000",
        timerProgressBar: "true",
      });
    } else {
      Swal.fire({
        title: "Wait",
        text: "You have to finish filling in the information.",
        icon: "info",
        button: "OK",
        timer: "3000",
        timerProgressBar: "true",
      });
    }
  };
  return (
    <div>
      <Helmet>
        <title>Contact - PM Garcia Gonzalez</title>
        <meta name="title" content="Contact - PM GARCIA GONZALEZ" />
        <meta
          name="description"
          content="Information about how you can contact PM Garcia Gonzalez."
        />
        <link rel="canonical" href="https://pmgarciagonzalez.com/contact" />
      </Helmet>
      <div className="contact container">
        <div className="row">
          <div className="col-lg-6 col-md-12 contact__first">
            <form onSubmit={sendEmail}>
              <div className="mb-3">
                <h3>Contact Form</h3>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="name"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="phone" className="form-label">
                    Phone
                  </label>
                  <div className="form-control">
                    <PhoneInput
                      international
                      defaultCountry="US"
                      value={value}
                      onChange={setValue}
                      name="phone"
                    />
                  </div>
                </div>
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  aria-describedby="emailHelp"
                  name="email"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="message" className="form-label">
                  Message
                </label>
                <textarea
                  className="form-control"
                  id="message"
                  name="message"
                  rows="3"
                  required></textarea>
              </div>

              <div className="mb-3 form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="1"
                  id="contactFormAgree"
                  onChange={check}
                  required
                />
                <label className="form-check-label" htmlFor="contactFormAgree">
                  I consent to the processing of the personal data I provide. I
                  have read and accept the{" "}
                  <Link to="/privacy" className="contact__link">
                    Privacy Policy
                  </Link>
                  .
                </label>
              </div>

              <button type="submit" className="button my-5" onClick={showAlert}>
                Submit
              </button>
            </form>
          </div>

          <div className="col-lg-6 col-md-12 contact__second">
            <h3>Contact Information</h3>
            <p>If you wish to contact us in usual ways. </p>

            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3231.014466592396!2d-84.07900068470695!3d35.92214592432894!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x885c253e25efba7b%3A0xd03741cb54e452e9!2s200%20Prosperity%20Dr%2C%20Knoxville%2C%20TN%2037923%2C%20USA!5e0!3m2!1sen!2ses!4v1648170803726!5m2!1sen!2ses"
              width="100%"
              height="250"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              title="usamap"></iframe>
            <hr />

            <div className="text-center" data-aos="fade">
              <i className="mx-3 mt-3 fas fa-phone-alt"></i>
              <strong>Phone</strong>
              <a
                href="tel:+18657661998"
                className="ms-2 text-decoration-none blue contact__link">
                (+1) 865 766 1998
              </a>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </div>
  );
}
