import React from 'react';
import './Footer.scss';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <div>
      <footer className="footer container-fluid">
        <div className="row text-center footer__primary">
          <div className="col-lg-4 col-sm-6 col-xs-6 p-0">
            <ul className="footer__primary-list ps-0">
              <li className="footer__link-margin">
                <Link className="footer__link link" to="/">
                  Home
                </Link>
              </li>
              <li className="footer__link-margin">
                <Link className="footer__link link" to="/services">
                  Services
                </Link>
              </li>
              <li className="footer__link-margin">
                <Link className="footer__link link" to="/contact">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-4 col-sm-6 col-xs-6 p-0">
            <ul className="footer__primary-list ps-0">
              
              <li className="footer__link-margin">
                <Link className="footer__link link" to="/about">
                About
                </Link>
              </li>
              <li className="footer__link-margin">
                <Link className="footer__link link" to="/projects">
                  Projects
                </Link>
              </li>
              
            </ul>
          </div>
          <div className="col-lg-4 col-sm-12 col-xs-12 footer__media p-0">
            <div className="justify-content-center py-4">
              <a
                className="footer__link my-3"
                href="https://twitter.com/pmgarciagonzalez"
              >
                <i className="footer__link-fontsize fab fa-twitter fa-2x px-3"></i>
              </a>
              <a
                className="footer__link my-3"
                href="https://www.facebook.com/pmgarciagonzalez"
              >
                <i className="footer__link-fontsize fab fa-facebook-f fa-2x px-3"></i>
              </a>
              <a
                className="footer__link my-3"
                href="https://www.linkedin.com/pmgarciagonzalez"
              >
                <i className="footer__link-fontsize fab fa-linkedin-in fa-2x px-3"></i>
              </a>
              <a
                className="footer__link my-3"
                href="https://www.instagram.com/pmgarciagonzalez"
              >
                <i className="footer__link-fontsize fab fa-instagram fa-2x px-3"></i>
              </a>
            </div>
          </div>
        </div>

        <div className="row footer__secondary">
          <div className="col-lg-6 footer__secondary-legal py-3 my-3">
            <Link
              className="footer__link text-start ms-4  px-2 link"
              to="/privacy"
            >
              Privacy Policy
            </Link>
            |
            <Link className="footer__link px-2 link" to="/legal">
              Legal
            </Link>
            |
            <Link className="footer__link px-2 link" to="/sitemap">
              Site-Map
            </Link>
          </div>
          <div className="col-lg-6 footer__secondary-copyright my-3">
            <p className="pt-3 mx-2">
              &copy; 2022 PM GARCIA GONZALEZ. All Rights Reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}
