import React from "react";
import Helmet from "react-helmet";
import BigBanner from "../../components/BigBanner/BigBanner";
import CarouselCards from "../../components/CarouselCards/CarouselCards";
import GetInTouch from "../../components/GetInTouch/GetInTouch";
import OurServices from "../../components/OurServices/OurServices";

function Home() {
  return (
    <div>
      <Helmet>
        <title>PM Garcia Gonzales</title>
        <meta name="title" content="PM GARCIA GONZALEZ" />
        <meta
          name="description"
          content="Project Management Consulting, Training and Project Team Services."
        />
        <link rel="canonical" href="https://pmgarciagonzalez.com/" />
        <meta property="og:title" content="PM GARCIA GONZALEZ - Project Management" />
        <meta property="og:url" content="https://pmgarciagonzalez.com/" />
        <meta property="og:image" content="https://i.imgur.com/aX8ObLo.png" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Project Management Consulting, Training and Project Team Services." />
        <meta property="og:locale" content="en_US" />
      </Helmet>
      <BigBanner />
      {/* <CarouselCards /> */}
      <OurServices />
      <GetInTouch />
    </div>
  );
}

export default Home;
