import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { Link } from 'react-router-dom';

export default function Cookie() {
  return (
    <div>
      <CookieConsent
        // debug={true}
        location="bottom"
        style={{
          textAlign: 'center',
          color: 'white',
          fontSize: '14px',
        }}
        buttonStyle={{
          color: 'white',
          fontSize: '12px',
          padding: '0.875rem 2.1875rem',
          background: 'linear-gradient(to left, $blue-jeans, $blue-logo)',
          borderRadius: '30px',
          border: 0,
          cursor: 'pointer',
          fontWeight: '400',
          textDecoration: 'none',
          transition: 'all 300ms ease-in-out',
        }}
        buttonText="ACCEPT ALL"
        buttonClasses="CookieConsent__button"
        expires={7}
      >
        This website uses cookies to improve your experience.{' '}
        <Link to="/privacy" className='link__withoutdec--cookie mx-1'>Read more</Link>. By clicking “Accept all”,
        you consent to the use of all cookies. You may also visit{' '}
        <a href="/" className='link__withoutdec--cookie mx-1' data-bs-toggle="modal" data-bs-target="#staticBackdrop">
          Cookie settings
        </a>
        and make individual choice.
      </CookieConsent>
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Cookie Settings
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p>
                This website uses various types of cookies. Some cookies are
                essential for functionality of the website and they are always
                enabled. Other cookies are used for analytical purposes, to
                enable a better user experience, and you can choose whether to
                enable or disable them.
              </p>
              <div className="flex flex-jc-sb my-2">
                <div>
                  <a
                    className="btn dropdown-toggle"
                    data-bs-toggle="collapse"
                    href="#collapseNecessary"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    Necessary
                  </a>
                </div>
                <div className="form-check form-switch">
                <input className="form-check-input mx-2 check" type="checkbox" id="flexSwitchCheckDefault"/>
                  <a
                    className="btn dropdown-toggle"
                    data-bs-toggle="collapse"
                    href="#collapseAnalytics"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    Analytics
                  </a>
                </div>
              </div>
              <div className="collapse" id="collapseNecessary">
                <div className="card card-body">
                  <p>
                    Necessary cookies are essential for the website to function
                    properly. These cookies ensure basic functionalities and
                    security of the website and they are always enabled (the
                    legal basis for data processing is Art. 6 (1) (f) GDPR).
                  </p>
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Cookie</th>
                        <th scope="col" className="noDisplay">
                          Type
                        </th>
                        <th scope="col">Duration</th>
                        <th scope="col" className="noDisplay">
                          Description
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">INGURSESSION</th>
                        <td className="noDisplay">0</td>
                        <td>Session</td>
                        <td className="noDisplay">
                          This cookie is set by Ingur.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          _stripe_mid
                        </th>
                        <td className="noDisplay">0</td>
                        <td>1 year</td>
                        <td className="noDisplay">
                          This cookie is set by Font Awesome.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">CookieConsent</th>
                        <td className="noDisplay">persistent</td>
                        <td>7 days</td>
                        <td className="noDisplay">
                          The cookie is set by the GDPR Cookie Consent plugin
                          and is used to store which changes user made to
                          cookies settings.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">_ga</th>
                        <td className="noDisplay">0</td>
                        <td>2 years</td>
                        <td className="noDisplay">
This cookie is set by Picsum Photos.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="collapse" id="collapseAnalytics">
                <div className="card card-body">
                  <p>
                    Analytics cookies help us understand how our visitors
                    interact with the website, e.g. analyze the number of
                    visitors, where the visitors are coming from, and the pages
                    they navigate. Analytics cookies are only set after users
                    have provided their consent (the legal basis for data
                    processing is Art. 6 (1) (a) GDPR). After consent has been
                    provided, it is possible to revoke it at any time here in
                    Cookies Settings.
                  </p>

                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Cookie</th>
                        <th scope="col" className="noDisplay">
                          Type
                        </th>
                        <th scope="col">Duration</th>
                        <th scope="col" className="noDisplay">
                          Description
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">_ga</th>
                        <td className="noDisplay">persistent</td>
                        <td>12 months</td>
                        <td className="noDisplay">
                          This is
                          used to calculate visitor, session, campaign data and
                          keep track of site usage for the site's analytics
                          report.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">_gid</th>
                        <td className="noDisplay">persistent</td>
                        <td>30 days</td>
                        <td className="noDisplay">
                          This cookie is used
                          to uniquely identify users across browsing sessions
                          based on a randomly generated number.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">_qca</th>
                        <td className="noDisplay">persistent</td>
                        <td>11 months</td>
                        <td className="noDisplay">
                          This cookie is set by Imgur.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="button" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
