import "./BigBanner.scss";

export default function BigBanner() {
  return (
    <div className="mb-5">
      <img
        src="https://i.imgur.com/V9Do2QS.jpg"
        alt="bigbanner-background"
        className="banner-img banner-img__black"
      />
      <div className="banner-img__header">
          <div className="typing-demo">Project Management Experts.</div>
      </div>
    </div>
  );
}
