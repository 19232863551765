import React from "react";
import Helmet from "react-helmet";

function Legal() {
  return (
    <div className="container-fluid maxWidth mb-5 margin-header">
      <Helmet>
        <title>Legal Terms - PM Garcia Gonzalez</title>
        <meta name="title" content="Legal Terms - PM GARCIA GONZALEZ" />
        <meta
          name="description"
          content="An overview of the legal terms of PM Garcia Gonzalez with information on their website. By visiting this website, you accept following Terms of Use"
        />
        <link rel="canonical" href="https://pmgarciagonzalez.com/legal" />
      </Helmet>
      <h1 className="text-center my-5" data-aos="fade"> <span className="mustard"> - </span>Legal <span className="mustard"> - </span></h1>
      <h6 data-aos="fade"> ACCEPTANCE OF TERMS </h6>
      <p data-aos="fade">By visiting this website, you accept the following Terms of Use:</p>

      <h6 data-aos="fade"> USE LIMITATION </h6>
      <p data-aos="fade">
        The documents and content contained herein are for personal and
        non-commercial use. You may not reproduce, publish, modify, copy,
        distribute, or sell any information on this site without explicit
        consent from PM Garcia Gonzalez.
      </p>

      <h6 data-aos="fade">
        THIRD PARTY <strong>LINKS</strong>
      </h6>
      <p data-aos="fade">
        This website may contain links to third party sites not under the
        control of PM Garcia Gonzalez. PM Garcia Gonzalez is not responsible for the
        content on such sites.
      </p>

      <h6 data-aos="fade">COPYRIGHT</h6>
      <p data-aos="fade">Copyright © 2021 - 2022 PM Garcia Gonzalez</p>
    </div>
  );
}

export default Legal;
