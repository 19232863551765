import React, { useEffect, useState } from "react";
import "./Header.scss";
import { Link } from "react-router-dom";

const change = () => {
  const header = document.querySelector(".header");
  const fadeElems = document.querySelectorAll(".has-fade");
  const body = document.querySelector("body");

  if (header.classList.contains("open")) {
    // Close Hamburger Menu
    body.classList.remove("noscroll");
    header.classList.remove("open");
    fadeElems.forEach(function (element) {
      element.classList.remove("fade-in");
      element.classList.add("fade-out");
    });
  } else {
    // Open Hamburger Menu
    body.classList.add("noscroll");
    header.classList.add("open");
    fadeElems.forEach(function (element) {
      element.classList.remove("fade-out");
      element.classList.add("fade-in");
    });
  }
};

function Header() {

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 1);
    });
  }, []);
  
  const path = window.location.pathname;
  const [scroll, setScroll] = useState(false);
  const classChanger =
    path === "/"
      ? scroll
        ? "header__links-link link"
        : "header__links-link link"
      : "header__links-link link";

  return (
    <header 
    className={
      scroll
        ? 'bg-white header fixed-top'
        : 'header fixed-top'
    }>
      <div className="overlay has-fade"></div>
      <nav className="flex flex-jc-sb flex-ai-c spacing">
        <Link className="header__logo" to="/">
          <img
            src="https://i.imgur.com/aX8ObLo.png"
            className="logo"
            alt="logo"
          />
        </Link>
        <span
          id="btnHamburger"
          className="header__toggle hide-for-desktop"
          onClick={change}>
          <span></span>
          <span></span>
          <span></span>
        </span>

        <div className="header__links hide-for-mobile">
          <Link className={classChanger} aria-current="page" to="/">
            Home
          </Link>
          <Link className={classChanger} to="/about">
            About
          </Link>
          <Link className={classChanger} to="/services">
            Services
          </Link>
          {/* <Link className={classChanger} to="/projects">
            Projects
          </Link> */}
        </div>

        <Link className="header__cta button hide-for-mobile" to="/contact">
          Contact
        </Link>
      </nav>

      <div className="header__menu has-fade">
        <a className="header__menu-links link" href="/">
          Home
        </a>
        <a className="header__menu-links link" href="/about">
          About
        </a>
        <a className="header__menu-links link" href="/services">
          Services
        </a>
        {/* <a className="header__menu-links link" href="/projects">
          Projects
        </a> */}
        <a className="header__menu-links link" href="/contact">
          Contact
        </a>
      </div>
    </header>
  );
}

export default Header;
