import "./App.scss";
import { useEffect } from "react";
import 'aos/dist/aos.css'
import Header from "./components/Header/Header";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NotFound from "./pages/NotFound/NotFound";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Services from "./pages/Services/Services";
import Projects from "./pages/Projects/Projects";
import Footer from "./components/Footer/Footer";
import Legal from "./pages/Legal/Legal";
import Privacy from "./pages/Privacy/Privacy";
import Cookie from "./components/Cookie/Cookie";
import Aos from 'aos';
import Sitemap from "./pages/Sitemap/Sitemap";

function App() {

  useEffect(() => {
    Aos.init({duration: 1500});
  }, [])
  
  return (
    <div>
      <Router>
      <Cookie/>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="sitemap" element={<Sitemap />} />
          {/* <Route path="projects" element={<Projects />} /> */}
          <Route path="services" element={<Services />} />
          <Route path="legal" element={<Legal />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
