import { useEffect } from "react";
import Helmet from "react-helmet";
import CarouselCards from "../../components/CarouselCards/CarouselCards";
import GetInTouch from "../../components/GetInTouch/GetInTouch";
import "./About.scss";

function About() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="margin-header mb-5">
      <Helmet>
        <title>About - PM Garcia Gonzalez</title>
        <meta name="title" content="About - PM Garcia Gonzalez" />
        <meta
          name="description"
          content="About page where you can find related information of us."
        />
        <link rel="canonical" href="https://pmgarciagonzalez.com/about" />
      </Helmet>
      <div className="about container-fluid">
        <h1 className="text-center my-5" data-aos="fade">
          <span className="mustard"> - </span> About Us
          <span className="mustard"> - </span>
        </h1>
        <p>
          Our mission is to <strong>partner with our clients</strong> to deliver
          a flexible combination of services that are tailored to their needs.
          PM Garcia Gonzalez uses a team of highly experienced and certified
          professionals to provide project management consulting, project
          management training and project portfolio management (PPM) services.
          Our goal is to give you a competitive advantage through improved
          project planning and control techniques. Our flexible combination of
          project management services is tailored to meet the individual needs
          of your organization.
        </p>
        <p>
          We develop long-term partnerships with your teams as we work together
          with the highest level of integrity. We have extensive experience
          supporting clients worldwide as they implement their strategic
          projects; positioning them for success when failure is not an option.
          Our project management consultants can work with your existing project
          office to identify opportunities for greater efficiency and better
          results. Our immediate project management solutions and long-term
          assistance can transform the way you manage projects.
        </p>

        <h3 className="text-center my-5" data-aos="fade">
          <span className="mustard"> - </span>Consultant Credentials
          <span className="mustard"> - </span>
        </h3>
        <p>
          All Senior Consultants have college degrees and many have
          advanced/multiple degrees. All Senior Consultants are Project
          Management Professionals certified through the Project
          Management Institute. Most Senior Consultants are Certified
          Professional Facilitators (CPFs) qualified through the International
          Association of Facilitators (IAF). All Senior Consultants receive
          continuing education on the latest project management technologies,
          software, and best practices.
        </p>

        <h3 className="text-center my-5" data-aos="fade">
          <span className="mustard"> - </span>Consultant Experience
          <span className="mustard"> - </span>
        </h3>
        <p>
          Our Senior Consultants have experience working with clients whose
          project teams include team members from multiple organizations,
          multiple locations, and multiple geographic regions. In addition, this
          experience includes projects having multiple project leaders, multiple
          project sponsors, and occasionally multiple business entities. Complex
          projects make the planning, control, reporting, and communication
          processes more challenging. PM Garcia Gonzalez consultants have the
          experience and leadership to keep all team members engaged and
          accountable and to communicate (orally and in writing) the project
          status to all levels of project stakeholders.
        </p>
        <h3 className="text-center my-5" data-aos="fade">
          <span className="mustard"> - </span> Quality Assurance
          <span className="mustard"> - </span>
        </h3>
        <p>
          PM Garcia Gonzalez has a continuous quality assurance program to
          ensure our Senior Consultants continually deliver top quality services
          to our clients. This program includes regular PM Garcia Gonzalez
          Partner quality audits at the client location & monthly PM Garcia
          Gonzalez Partner reviews of project metrics.
        </p>

        <h3 className="text-center my-5" data-aos="fade">
          <span className="mustard"> - </span> Software / IT Support
          <span className="mustard"> - </span>
        </h3>
        <p>
          PM Garcia Gonzalez has software developers who provide customized
          project management solutions and technical / IT support to our
          consultants and clients.
        </p>
      </div>
      {/* <CarouselCards /> */}
      <GetInTouch />
    </div>
  );
}

export default About;
