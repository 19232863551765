import React from "react";
import {
  AiOutlineMessage,
  AiOutlineStock,
  AiOutlineCheck,
} from "react-icons/ai";
import { FaRegLightbulb } from "react-icons/fa";
import { Link } from "react-router-dom";

function OurServices() {
  return (
    <div className="maxWidth container">
      <h1 className="center-header text-center my-5">
        <span className="mustard"> - </span> Project Management Consulting &
        Training <span className="mustard"> - </span>
      </h1>
      <p className="mb-3">
        At PM Garcia Gonzalez we partner with our clients to provide
        mission-critical project management training and project management
        consulting services. Through our methodology, the name PM Garcia
        Gonzalez has become synonymous with trust, reliability and success.
      </p>
      <div className="row">
        <div className="col-md-12 col-lg-6 px-5">
          <Link to="/services" className="link__withoutdec">
            <h3 className="my-5 text-center">
              <span className="mustard"> - </span>Our Services
              <span className="mustard"> - </span>
            </h3>
          </Link>

          <Link to="/services#A" className="link__withoutdec">
            <h5>
              <FaRegLightbulb className="mustard mx-3" /> Project Management
              Consulting
            </h5>
          </Link>
          <p className="mb-3">
            Our consultants provide world-class facilitation and planning
            services to help our clients develop and maintain their plans...
          </p>

          <Link to="/services#B" className="link__withoutdec">
            <h5>
              <AiOutlineMessage className="mustard mx-3" /> Customized PM
              Solutions
            </h5>
          </Link>
          <p className="mb-3">
            Our customized solutions are built around a proven methodology and
            tailored to the client's needs...
          </p>

          <Link to="/services#C" className="link__withoutdec">
            <h5>
              <AiOutlineCheck className="mustard mx-3" /> Project Management
              Training
            </h5>
          </Link>
          <p className="mb-3">
            Our industry expertise and training helps client
            teams build a foundation for success...
          </p>

          <Link to="/services#D" className="link__withoutdec">
            <h5>
              <AiOutlineStock className="mustard mx-3" /> Project Team Development
            </h5>
          </Link>
          <p className="mb-3">
            We develop, implement and support project offices in companies
            worldwide...
          </p>
        </div>
        <div className="col-md-12 col-lg-6 px-5">
          <Link to="/services#E" className="link__withoutdec">
            <h3 className="text-center my-5">
              <span className="mustard"> - </span>Our Methodology
              <span className="mustard"> - </span>
            </h3>
          </Link>
          <p>
            Project Management isn't just about timelines and deliverables, it's
            also about your firm's profits, reputation, stability and growth. In
            today's competitive business environment, companies must be able to
            deliver the benefits of successful projects in a more cost-effective
            manner.
          </p>
          <p>
            From time to time companies embark on projects that are strategic in
            nature and simply “too important to fail”. Strategic Projects also
            tend to be extraordinarily complex relative to other projects that
            companies undertake. They require an elevated level of project
            management expertise, advanced diagnostic tools and a higher degree
            of focus than what organizations can typically provide internally.
          </p>

          <p>
            At PM Garcia Gonzalez we utilize our methodology to
            enable our clients to successfully complete their strategic projects
            on-time, within budget and to the intended level of quality. We
            provide a flexible combination of project management services
            including a project management training curriculum,
            best-in-class project management consulting services, end-to-end
            Project Team development and proprietary software add-in tools.
          </p>
        </div>
      </div>
    </div>
  );
}

export default OurServices;
