import { useEffect } from "react";
import Helmet from "react-helmet";
import CarouselCards from "../../components/CarouselCards/CarouselCards";
import GetInTouch from "../../components/GetInTouch/GetInTouch";
import "./Services.scss";

function Services() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="margin-header services mb-5">
      <Helmet>
        <title>Services - PM Garcia Gonzalez</title>
        <meta name="title" content="Services - PM Garcia Gonzalez" />
        <meta
          name="description"
          content="Project Management Services provided Us. Including Project Management Consulting, Training and Project Team Development Services."
        />
        <link rel="canonical" href="https://pmgarciagonzalez.com/services" />
      </Helmet>
      <div className="maxWidth container-fluid">
        <h1 className="text-center my-5" data-aos="fade">
          <span className="mustard"> - </span>Project Management Services
          <span className="mustard"> - </span>
        </h1>
        <p>
          Clients choose PM Garcia Gonzalez because of our unique ability to
          provide project management consulting solutions they need to match the
          task at hand. That flexibility means we are a “one-stop shop”, with a
          complete range of customized services delivered by project management
          experts.
        </p>

        <img
          src="https://i.imgur.com/38mUJyZ.jpg"
          className="container my-5 banner-img__black services__img"
          alt="meeting"
          data-aos="fade"
        />

        <p className="mt-3">
          Our services include project management training, consulting, project
          office development, and custom project management solutions. As part
          of our strategy, we offer a complete curriculum of training programs
          and employ project consultants who hold credentials from PMI® and the
          International Association of Facilitators (IAF).
        </p>
        <h3 className="text-center mt-5" data-aos="fade">
          <span className="mustard"> - </span> Industries We Service
          <span className="mustard"> - </span>
        </h3>
        <div className="row text-center">
          <div className="col-md-12 col-lg-6">
            <ul>
              <li className="services__li">Aerospace & Defenses</li>

              <li className="services__li">Agriculture & Forestry</li>

              <li className="services__li">Biotechnology & Chemicals</li>

              <li className="services__li">Communications</li>

              <li className="services__li">Financial & Insurance</li>

              <li className="services__li">Food & Beverage</li>
            </ul>
          </div>
          <div className="col-md-12 col-lg-6">
            <ul>
              <li className="services__li">Government</li>

              <li className="services__li">Healthcare</li>

              <li className="services__li">Machinery & Manufacturing</li>

              <li className="services__li">Pharmaceuticals</li>

              <li className="services__li">Utilities</li>
            </ul>
          </div>
        </div>
        <hr />
        <h1 className="mt-5 text-center" id="A">
          <span className="mustard"> - </span> Project Management Consulting
          <span className="mustard"> - </span>
        </h1>

        <h3 className="my-5 text-center">
          <span className="mustard"> - </span> Why PM Garcia Gonzalez?
          <span className="mustard"> - </span>
        </h3>
        <p>
          PM Garcia Gonzalez is a project management consulting, project
          portfolio management and training firm that partners with
          project teams to ensure their important projects are planned,
          controlled and delivered successfully. Our most valuable assets are
          our consultants, methodology, and technology and tools. We use these
          assets to give our clients a competitive advantage in executing their
          time-sensitive projects. Our consultants are certified through the
          Project Management Institute and the International Association of
          Facilitators. They follow our proven methodology to facilitate project
          teams in creating and maintaining credible plans. Our technology and
          tools automates cumbersome project management functions which keeps
          our cost down and allows client team members to stay engaged without
          creating an undue burden on their productivity.
        </p>

        <p>
          For each project PM Garcia Gonzalez partners with, we provide a custom
          team of consultants. You can learn more about each role below. Based
          on the needs of the project / urgency, we will assemble a custom team
          with the level of experience and size that your project requires.
        </p>

        <h3 className="my-5 text-center" data-aos="fade">
          <span className="mustard"> - </span>What Benefits Can You Expect With
          PM Garcia Gonzalez?
          <span className="mustard"> - </span>
        </h3>

        <h5>Immediate Visibility</h5>
        <p>
          You will gain insight on day-one on whether the expected finish date
          of the project is doable.
        </p>

        <h5>Promote Buy-In and Commitment</h5>
        <p>
          You will have a project plan that is based on team member buy-in and
          commitment, two essential elements of successful projects.
        </p>

        <h5>Trust The Plan</h5>
        <p>
          You will have accurate and complete schedules for making informed
          business decisions.
        </p>

        <h5>Assurances on Progress</h5>
        <p>
          You will know if the team is making progress each week, positive gains
          that ensures the expected finish date remains doable.
        </p>

        <h5>Early Warning System</h5>
        <p>
          You will have an early warning system that alerts you when the team's
          progress is slowing to a point that requires corrective actions in
          order to avoid delays in the future.
        </p>

        <h5>Executive Status</h5>
        <p>
          You will have access to an Executive Dashboard so you can quickly
          evaluate the status of the key milestone dates and review the
          corrective actions the team is undertaking to remedy any delays.
        </p>

        <h5>Aligned Execution</h5>
        <p>
          You will have a weekly control process that provides the structure to
          keep the schedule current and the discipline to keep team members
          engaged and accountable.
        </p>

        <h3 className="my-5 text-center" data-aos="fade">
          <span className="mustard"> - </span>Detailed Breakdown of Our Services
          <span className="mustard"> - </span>
        </h3>
        <p>
          Our Consultants lead large cross-functional project teams on strategic
          projects. They regularly facilitate:
        </p>
        <ul>
          <li>Program charters that define scope and goals.</li>
          <li>High-level and detailed network-based schedules.</li>
          <li>
            Activity identifications, including durations, responsibilities and
            resources.
          </li>
          <li>The time-cost tradeoff process and validation of schedules.</li>
          <li>Staffing analysis.</li>
          <li>Validation and compression of schedules.</li>
          <li>
            Establishment of governance procedures, standard reports, coding
            structures, metrics and templates.
          </li>
          <li>
            Work Breakdown Structures that identify work phases, deliverables,
            and activities.
          </li>
          <li>Validation and compression of schedules.</li>
          <li>Generation of status memos for key stakeholders.</li>
          <li>
            Client instruction in the use of project management tools/methods.
          </li>
          <li>Generate reports to inform stakeholders of project status.</li>
          <li>Identify areas that need attention by the project manager.</li>
          <li>Enter data into the project management software.</li>
          <li>Update project files during the normal schedule update cycle.</li>
          <li>Update Web sites, shared drives and databases.</li>
          <li>
            Serve as an onsite expert in project management software tools.
          </li>
        </ul>
        <hr />
        <h2 className="text-center my-5" data-aos="fade" id="B">
          <span className="mustard"> - </span> Customized Project Management
          Solutions <span className="mustard"> - </span>
        </h2>
        <p>
          PM Garcia Gonzalez knows from experience that implementing structured
          project planning and control techniques can be difficult. They're a
          departure from the norm for many organizations, and resistance to
          change should be expected—and even planned on. Shifting the practices
          within your project team or project office will take a measured
          approach and the support of experienced project management
          consultants. The need for advanced project management expertise
          becomes even more critical when managing strategic projects, which are
          complex, high visibility, and often carry significant financial impact
          for your organization.
        </p>

        <img
          src="https://i.imgur.com/RInepNm.jpg"
          className="container my-5 banner-img__black services__img"
          alt="meeting"
          data-aos="fade"
        />

        <p>
          Companies have varying levels of project management knowledge, which
          is why a “one-size-fits-all” approach won't work. It doesn't recognize
          differences in project types, management and staff capabilities, and
          organizational culture. Our project management consultants not only
          are excellent facilitators; they're also well trained and experienced
          in recognizing these differences and providing customized project
          management solutions to overcome them.
        </p>
        <hr />
        <h1 className="text-center my-5" data-aos="fade">
          <span className="mustard"> - </span> Project Management Training
          <span className="mustard"> - </span>
        </h1>
        <p>
          PM Garcia Gonzalez is an approved Registered Education Provider (REP)
          by the Project Management Institute. Whether you are pursuing
          or maintaining certification from the PMI®, you can receive
          Professional Development Units from each project management
          training course we offer.
        </p>

        <h5>Successful Teams Need Ongoing Training</h5>
        <p>
          Committed project management teams accomplish more-and one way to
          ensure their commitment is to involve them in the planning process. PM
          Garcia Gonzalez provides project team members the right skills and
          knowledge to participate. As a part of our project management
          consulting services, we offer project management methodology, project
          management software and facilitator training for all stakeholders in
          your organization-from team members to managers, project sponsors to
          customers. We also have advanced project management training classes
          for your experienced managers and individuals involved in project
          office development.
        </p>

        <img
          src="https://i.imgur.com/xU9Trnk.jpg"
          className="container my-5 banner-img__black services__img"
          alt="meeting"
          data-aos="fade"
        />

        <h5 className="my-3">Why Take a PM Garcia Gonzalez Training Course?</h5>
        <p>
          PM Garcia Gonzalez project management training fills the project
          management knowledge gap between team members and provides a
          consistent approach for use by the entire team. Our trainers use a state-of-the-art curriculum to pose practical
          challenges, cultivate strong working relationships and arm your team
          with tools they can put to immediate use. They will be well equipped
          to participate in creating comprehensive project plans, implementing
          disciplined processes for maintaining those plans, and utilizing
          management reviews to ensure deliverables are completed in a timely
          manner.
        </p>

        <hr />
        <h1 className="text-center my-5" data-aos="fade">
          <span className="mustard"> - </span> Project Office Development
          <span className="mustard"> - </span>
        </h1>

        <p>
          An internal Project Management Office (Project Team) gives
          organizations the ability to achieve consistently successful results
          across their entire portfolio of projects-creating a foundation that
          can turn project management into a competitive advantage. Whether your
          organization is just beginning to explore the benefits of a formal
          Project Team, or your existing Project Team is not achieving the
          results management has intended, PM Garcia Gonzalez can help take your
          Project Team to the next level.
        </p>

        <img
          src="https://i.imgur.com/OJijyaT.jpg"
          className="container my-5 banner-img__black services__img"
          alt="meeting"
          data-aos="fade"
        />

        <p className="mt-3">A Project Office fulfills two essential roles:</p>

        <ul>
          <li>It provides enterprise-level project management services.</li>
          <li>It serves as a project management center of excellence.</li>
        </ul>
        <p>
          Implementing a formalized project management process in an
          organization that has not used a structured approach to project
          planning and control can present significant challenges. Our phased
          approach to implementation helps companies overcome resistance to
          change, allows lessons learned in early phases to be incorporated in
          the systems installed in later phases, and ensures that a solid
          foundation of project-level data is available prior to rolling-up
          enterprise-level information.
        </p>

        <h6>
          PM Garcia Gonzalez uses four implementation phases: Initiation,
          Project-Level Installation, Enterprise-Level Installation, and
          Maintenance
        </h6>

        <h3 className="text-center my-5" data-aos="fade">
          <span className="mustard"> - </span> The Initiation phase:
          <span className="mustard"> - </span>
        </h3>

        <ul>
          <li>Mobilizes the organization.</li>
          <li>Remediates any current at-risk projects.</li>
          <li>Sets the stage for the Installation phases.</li>
        </ul>

        <p>
          This phase includes the selection of pilot projects that have the
          potential for near-term successes, and the communication of those
          success stories to the organization.
        </p>

        <h3 className="text-center my-5" data-aos="fade">
          <span className="mustard"> - </span>The Project-Level Installation
          phase:<span className="mustard"> - </span>
        </h3>
        <ul>
          <li>
            Provides a structured project planning and control process for all
            targeted projects.
          </li>
          <li>
            Creates the project management infrastructure necessary to support
            the consistent, successful application of project management
            techniques by the Project Office on future projects.
          </li>
          <li>Provides training and mentoring to the Project Office staff.</li>
        </ul>

        <h3 className="text-center my-5" data-aos="fade">
          <span className="mustard"> - </span>The Enterprise-Level Installation
          phase:<span className="mustard"> - </span>
        </h3>

        <ul>
          <li>
            Creates the infrastructure necessary to support business
            decision-making based on schedule, resource, and cost information
            “rolled-up” from the entire portfolio of projects.
          </li>
          <li>
            Transitions the day-to-day responsibility for developing and
            maintaining individual project plans from PM Garcia Gonzalez to the
            Project Office staff.
          </li>
        </ul>

        <h3 className="text-center my-5" data-aos="fade">
          <span className="mustard"> - </span>The Maintenance phase:
          <span className="mustard"> - </span>
        </h3>

        <ul>
          <li>
            Transitions the responsibility for supporting all of the project
            management requirements of the organization to the Project Office
            staff.
          </li>
          <li>
            Ensures long-term continuity by establishing project management as a
            core competency and an essential function within the organization.
          </li>
        </ul>
        <hr />
        <h3 className="text-center my-5" data-aos="fade">
          <span className="mustard"> - </span> Project Management Methodology
          <span className="mustard"> - </span>
        </h3>

        <p>
          Organizations that implement a shared project management methodology
          to guide planning and execution see a number of benefits. But it isn't
          enough to have everyone come together around a common methodology for
          a single project. Teams that jump between strategies as they wrap up
          one initiative and begin another often encounter challenges when it
          comes to achieving good results. In the long run, businesses that
          don't have a stable methodology in place aren't able to get good value
          from their project management efforts.
        </p>

        <img
          src="https://i.imgur.com/fLRZViW.jpg"
          className="container my-5 banner-img__black services__img"
          alt="meeting"
          data-aos="fade"
        />

        <p>
          Some of the advantages of a consistent project methodology are well
          known—standardization across metrics and reporting formats, similar
          measures used to gauge progress, etc.—but those are just the
          beginning. If your company uses a mix of project management
          methodologies or doesn't seem to stay with any one methodology for
          long, consider where you may be creating your own roadblocks.
        </p>

        <p>
          When a project team transitions from one methodology to another, waste
          and inefficiency are unfortunate byproducts. Historical data, such as
          budget estimates and task durations, may no longer be useful in their
          original state. The techniques and standards used to generate or
          measure metrics could vary from the previous methodology to the next
          one. Forecasts and estimates based on this data may be flawed,
          creating the potential for further problems. Your group might need to
          either translate previous metrics so they can be used in the current
          project or recreate the necessary data. Both consume time that should
          be focused on higher-value activities.
        </p>

        <p>
          Without a consistent project management methodology in place, you may
          find that technology tools don't function effectively or aren't able
          to align with your changing workflows. Optimizing your technology
          investments will be a moving target and you could experience gaps
          between your needs and your software solutions' capabilities.
          Challenges could come in the form of incompatible file types, poorly
          integrated systems, proprietary hardware that doesn't work with newer
          or different platforms, or a constant need for retraining and
          reconfiguring. This problem is compounded in an environment where the
          organization itself is undergoing a digital transformation and parts
          of the infrastructure are in flux for other reasons.
        </p>

        <p>
          Whether you routinely jump between project methodologies or you swap
          out specific workflows and techniques, the lack of stability can cause
          big problems in your career development efforts. Junior-level team
          members who are still learning the ropes will not only be confused by
          the lack of consistency, they're at risk of also establishing bad
          habits that will be difficult to unlearn. And the challenges are
          intensified in today's global marketplace, where team members are
          increasingly exposed to new project management thinking, new
          recommendations, and new regulatory requirements from a wide range of
          industry leaders.
        </p>

        <p>
          You're more likely to fall short of meeting the expectations of
          sponsors and stakeholders when you don't have a stable project
          management methodology in place. The techniques used to gather input,
          establish the scope of your projects, develop targets and
          deliverables, assess risks, and even communicate with key supporters
          and influencers might not persist if you switch methodologies. In this
          scenario, achieving consistently good results is likely to prove
          extremely challenging. The issue gains prominence when your
          stakeholder base includes people from different backgrounds—perhaps
          located in disparate regions, coming from different industries, or
          having perspectives that are based in the community rather than within
          a single business entity. If your team is already struggling to
          implement a new methodology and now there isn't any common knowledge
          or too few shared practices to help link everyone to the effort, you
          may be challenged to push through project conflicts and issues to
          achieve success.
        </p>

        <p>
          PM Garcia Gonzalez uses a team of highly experienced and certified
          professionals to provide project management consulting, project
          management training and project office development services.
        </p>
      </div>
      {/* <CarouselCards /> */}
      <GetInTouch />
    </div>
  );
}

export default Services;
