import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";

function Sitemap() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="margin-header mb-5 text-center container" data-aos="fade">
      <Helmet>
        <title>Sitemap - PM Garcia Gonzalez</title>
        <meta name="title" content="Sitemap - PM Garcia Gonzalez" />
        <meta
          name="description"
          content="Sitemap of PM Garcia Gonzalez where you can see all of our url's."
        />
        <link rel="canonical" href="https://pmgarciagonzalez.com/sitemap" />
      </Helmet>
      <h1 className="my-5"><span className="mustard"> - </span>Sitemap<span className="mustard"> - </span></h1>
      <Link to="/" className="link footer__link"><p>https://pmgarciagonzalez.com/</p></Link>
      <Link to="/about" className="link footer__link"><p>https://pmgarciagonzalez.com/about</p></Link>
      <Link to="/services" className="link footer__link"><p>https://pmgarciagonzalez.com/services</p></Link>
      <Link to="/projects" className="link footer__link"><p>https://pmgarciagonzalez.com/projects</p></Link>
      <Link to="/contact" className="link footer__link"><p>https://pmgarciagonzalez.com/contact</p></Link>
      <Link to="/privacy" className="link footer__link"><p>https://pmgarciagonzalez.com/privacy</p></Link>
      <Link to="/legal" className="link footer__link"><p>https://pmgarciagonzalez.com/legal</p></Link>
      <Link to="/sitemap" className="link footer__link"><p>https://pmgarciagonzalez.com/sitemap</p></Link>

    </div>
  );
}

export default Sitemap;
