import { Link } from "react-router-dom";
import "./GetInTouch.scss";

export default function GetInTouch() {
  return (
    <div className="getTouch my-5">
      <div className="text-center p-5 getTouch__contact">
        <h1 className="mx-auto center-header" data-aos="fade">Not sure what you need?</h1>
        <p className="mx-auto m-5 getTouch__contact-color" data-aos="fade">
          Contact us and our team will resolve your concerns
        </p>
        <Link className="button" to="/contact">
          Get in Touch
        </Link>
      </div>
    </div>
  );
}
